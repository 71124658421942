.App {
  text-align: center;
}
.body {
  background-image: url('/public/new_theme/bg.png');
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header-nav .nav-link {
  color: #fff;
  font-weight: bold;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.body {
  background-color: #084092;
}
.header {
  background: url('/public/imgs/background.png');
  background-size: 30px;
}
.blinking {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
  vertical-align: text-top;
  
}

@keyframes "blink" {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes "blink" {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes "blink" {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes "blink" {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.content {
  position: relative;
  width: 880px;
  margin: 0 auto;
  max-width: 100%;
  /* padding: 20px; */
}
.content video {
  width: 100%;
  display: block;
}
.content .overlay {
  content: '';
  position: absolute;
  background: url('/public/new_theme/khungtren.png');
  border-radius: 5px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;    z-index: 999;
  text-align: center;
  background-repeat: no-repeat;
  font-size: 26px;
  font-weight: bold;
  color: #fff;
}

.content .fightNumOverlay {
  content: '';
  width: 200px;
  height: 50px;
  position: absolute;
  background: url('/public/new_theme/fight10.png');
  border-radius: 5px;
  top: 16px;
  right: 0;
  bottom: 0;
  left: 30px;    z-index: 999;
  text-align: center;
  background-repeat: no-repeat;
  font-size: 26px;
  font-weight: bold;
  color: #fff;
}
.content .fightStatusOverlay {
  content: '';
  width: 200px;
  height: 60px;
  position: absolute;
  background: url('/public/new_theme/live.png');
  border-radius: 5px;
  top: 10px;
  right: 0;
  bottom: 0;
  z-index: 999;
  text-align: center;
  background-repeat: no-repeat;
  font-size: 26px;
  font-weight: bold;
  color: #fff;
}

@media (max-width: 668px) {
 .overlay {
  background-size: contain !important;
  font-size: 1em !important;
 }
 .fightNumOverlay {
  width: 65px !important;
  background-size: contain !important;
 }
 .fightStatusOverlay {
  width: 65px !important;
  background-size: contain !important;
  right: 20px !important;
 }
}