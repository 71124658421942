.dot {
    height: 30px;
    width: 30px;
    background-color: transparent;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
  }

  .meron, .m{
    background-color: #ff0000;
  }
  .wala, .w{
    background-color: #0a7ec5;
  }
  .draw, .d{
    background-color: #2bae70;
  }
  .cancel, .c{
    background-color: #656566;
  }